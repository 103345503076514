<template>
  <div class="main">
    <div class="allSubmit">
      <van-button type="primary" size="small" block @click="allSubmit"
        >通过</van-button
      >
      <van-button type="danger" size="small" block @click="cancelSubmit"
        >中止</van-button
      >
    </div>
    <div class="main_cont">
      <div v-for="(item, key, index) of listObj" :key="index">
        <template v-if="key != 5">
          <van-divider
            content-position="left"
            :style="{
              color: '#1989fa',
              borderColor: '#1989fa',
              padding: '0 6px',
              margin: '6px 0'
            }"
            >{{ item[0].types_name }}</van-divider
          >
          <div class="checkBox" v-for="(items, index) in item" :key="index">
            <van-form @submit="sigleSubmit(items)">
              <van-cell-group inset>
                <div class="title">{{ index + 1 }}、{{ items.name }}</div>
                <van-field
                  name="radio"
                  :rules="[{ required: true, message: '请选择' }]"
                  v-if="items.types != 4"
                >
                  <template #input>
                    <van-radio-group
                      direction="horizontal"
                      v-model="items.ischek"
                    >
                      <van-radio name="is_y" icon-size="20px">Y</van-radio>
                      <van-radio name="is_n" icon-size="20px">N</van-radio>
                      <van-radio name="is_suit" icon-size="20px">N/A</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <div
                  v-if="items.types == 4"
                  style="padding:10px;white-space: pre-line;font-size:15px"
                >
                  {{ items.content }}
                </div>
                <van-field
                  name="radio"
                  :rules="[{ required: true, message: '请选择' }]"
                  v-if="items.types == 4"
                >
                  <template #input>
                    <van-radio-group
                      direction="horizontal"
                      v-model="items.ischek"
                    >
                      <van-radio name="is_y" icon-size="20px">Y</van-radio>
                      <van-radio name="is_n" icon-size="20px">N</van-radio>
                      <van-radio name="is_suit" icon-size="20px">N/A</van-radio>
                    </van-radio-group>
                  </template>
                </van-field>
                <van-field
                  v-if="items.ischek == 'is_n'"
                  v-model="items.commention"
                  rows="1"
                  autosize
                  label="备注"
                  type="textarea"
                  maxlength="500"
                  placeholder="备注"
                  show-word-limit
                />
                <van-field
                  name="uploader"
                  label="图片内容"
                  v-if="
                    items.types == 4
                      ? true
                      : items.ischek == 'is_n'
                      ? true
                      : false
                  "
                >
                  <template #input>
                    <van-icon
                      name="chat-o"
                      @click="showImg(items.attachment1)"
                      size="40"
                      :badge="items.attachment1 ? items.attachment1.length : 0"
                    />
                  </template>
                  <template #button>
                    <van-button
                      size="mini"
                      type="primary"
                      @click="takephoto(key, index)"
                      >拍照上传</van-button
                    >
                    <van-button
                      size="mini"
                      type="primary"
                      @click="checkphoto(key, index)"
                      >相册上传</van-button
                    >
                  </template>
                </van-field>
              </van-cell-group>
            </van-form>
          </div>
        </template>
      </div>
      <template v-if="typeId == 7 || typeId == 8">
        <div
          class="checkBox"
          v-for="(items, index) in locationList"
          :key="index"
        >
          <div class="title">
            {{ items.ptw_gas_inspection_address }}气体检测
          </div>
          <van-cell-group inset>
            <van-field
              v-model="items.lel"
              label="可燃气体"
              placeholder="LEL 必填"
            >
              <template #button>
                <van-switch
                  v-model="items.lel_checked"
                  active-color="#ee0a24"
                  inactive-color="#dcdee0"
                  size="20px"
                />
              </template>
            </van-field>
            <van-field
              v-model="items.ppm"
              label="有毒气体"
              placeholder="有毒气体PPM 必填"
            >
              <template #button>
                <van-switch
                  v-model="items.ppm_checked"
                  active-color="#ee0a24"
                  inactive-color="#dcdee0"
                  size="20px"
                />
              </template>
            </van-field>
            <van-field v-model="items.o2" label="o2" placeholder="o2 必填">
              <template #button>
                <van-switch
                  v-model="items.o2_checked"
                  active-color="#ee0a24"
                  inactive-color="#dcdee0"
                  size="20px"
                />
              </template>
            </van-field>
            <van-field
              type="textarea"
              v-model="items.commention"
              label="备注"
              autosize
              rows="1"
              placeholder="备注"
            />
          </van-cell-group>
        </div>
      </template>
    </div>
    <!-- 签名 -->
    <handwritingHtml
      @writingCommit="writingCommit"
      ref="wirteDialog"
    ></handwritingHtml>
    <!-- 分析点位置气体检测 -->
    <van-popup v-model:show="showLocation" position="bottom">
      <van-picker
        :columns="locationList"
        @confirm="onConfirm"
        @cancel="showLocation = false"
      />
    </van-popup>
  </div>
</template>
<script>
/* eslint-disable */
import { Notify ,Dialog,ImagePreview} from 'vant';
import {reactive,ref, onMounted,getCurrentInstance} from 'vue';
import {useRoute,useRouter} from 'vue-router';
import Http from '../../../api/http';
import handwritingHtml from '../../../components/handwriting.vue';
export default {
    components:{handwritingHtml},
    setup() {
        const route = useRoute();
        const $this = getCurrentInstance();
        const list = ref([]);
        const typeId = ref(route.params.typeId);
        const rtList = (list) =>{
            var coList = [];
            list.forEach(elem=>{
                coList.push({ptw_gas_inspection_address:elem,lel:'',is_lel_alert:'0',is_ppm_alert:'0',is_o2_alert:'0',ppm:'',o2:'',commention:''})
            });
            return coList;
        };
        const locationList = ref(rtList(route.params.location.split(',')));
        const query = reactive({
            method:'query',
            queryId:183,
            ptw_cetificate_id:route.params.id,
            types:route.params.status == 5?'1,2,3,4':'4'
        });
        // 气体检测
        const seeForm = reactive({
            ptw_gas_inspection_address:'',
            lel:'',
            ppm:'',
            o2:'',
            commention:''
        });
        
        const showLocation = ref(false)
        const onConfirm = (data) =>{
            seeForm.ptw_gas_inspection_address = data;
            showLocation.value = false;

        }
        const router = useRouter();
        const listObj = reactive({})
        // 获取检查项目
        const getCheckList = () =>{
            Http.get(query,false,true)
            .then(res =>{
                res.listptw_check_option_value.forEach(elem=>{
                    if(!listObj[elem.types]){
                        listObj[elem.types] = [];
                        listObj[elem.types].push(elem)
                    }else{
                        listObj[elem.types].push(elem)
                    }
                });
            })
            .catch(()=>{})
        }
        // 单个提交
        const sigleSubmit = (item) =>{
            item.is_y = 0;item.is_n = 0;item.is_suit = 0;
            item[item.ischek] = 1;
            console.log(item)
        }
        // 全部通过
        const gps_location = ref('');
        const writingCommit = (res)=>{

            var attachment3 = [];
            attachment3.push({document_path:res,document_code:'confirm'});
            locationList.value.forEach(items=>{
                items.is_lel_alert = items.lel_check == true ? 1:0;
                items.is_ppm_alert = items.ppm_check == true ? 1:0;
                items.is_o2_alert = items.o2_check == true ? 1:0;
                items.attachment3 = attachment3;
                items.gps_location = gps_location.value;
            })

            Http.post({
                method:'saveorupdate',
                t:'PTW_CETIFICATE',
                action:'success',
                id:route.params.id,
                PTW_CHECK_OPTION_VALUE:list.value,
                attachment3:attachment3,
                PTW_GAS_INSPECTION:locationList.value
            },false,true)
            .then(()=>{
                router.push('/ptwDetail/'+route.params.id)
            })
            .catch(()=>{})
            // Dialog.confirm({
            //     title: '标题',
            //     message: '是否确认提交检查项结果 ？',
            // })
            // .then(() => {
                
            // })
            // .catch(() => {});
                
        }
        const allSubmit = () =>{
            var indexList = [];
            for(var key in listObj){
                // debugger
                listObj[key].forEach((elem,index) =>{
                    elem.is_y = 0;elem.is_n = 0;elem.is_suit = 0;
                    elem[elem.ischek] = 1;
                    delete elem.id
                    list.value.push(elem);
                    if(elem.types!=5){
                        if(!elem.ischek){
                            indexList.push(elem.types_name+'(第'+(index + 1)+'条)');
                        }
                    }
                });
            }
            if(indexList.length > 0){
                return Notify({ type: 'danger', message: `${indexList.join(',')}项未处理,请确认后在提交` });
            }
            var locationKey = [];
            locationList.value.forEach((items)=>{
                if(items.lel == ''||items.ppm == ''||items.o2==''){
                    locationKey.push(items.ptw_gas_inspection_address);
                }
            });
            if((typeId.value==7 || typeId.value==8) && locationKey.length > 0){
                return Notify({ type: 'danger', message: `${locationKey.join('、')} 气体检测内容填写不完整,请确认后在提交` });
            }
            navigator.geolocation.getCurrentPosition(function(position){
                gps_location.value = position.coords.longitude + ','+position.coords.latitude 
                $this.refs.wirteDialog.show();

            }, 
            function(error){    
                gps_location.value = '0,0'
                $this.refs.wirteDialog.show();
            });
            $this.refs.wirteDialog.show();
        }
        // 退回
        const cancelSubmit = () =>{
            Dialog.confirm({
                title: '标题',
                message: '确认中止操作 ？',
            })
            .then(() => {
                Http.get({
                    method:'saveorupdate',
                    t:'PTW_CETIFICATE',
                    action:'close',
                    id:route.params.id
                },false,true)
                .then(()=>{
                    router.push('/ptwDetail/'+route.params.id)
                })
                .catch(()=>{})
            })
            .catch(() => {});
        }
        // 照片回调
        const imageurl = ref('');
        const onSuccess = (data,key,index) =>{
            imageurl.value = "data:image/png;base64," + data
            var file = Http.dataURLtoFile(imageurl.value);
            let formData = new FormData();
            formData.append('file', file, file.name);
            Http.fileuoload(formData)
            .then(res=>{
                // alert(res)
                // alert(listObj[key][index].toString())
                if(!listObj[key][index].attachment1){
                    listObj[key][index].attachment1 = [];
                    listObj[key][index].attachment1.push({document_path:res})
                }else{
                    listObj[key][index].attachment1.push({document_path:res})
                }
                Http.loading.close()
            })
            .catch((err)=>{
                // alert(2)
                alert(err.toString());
                Http.loading.close()
            });
        }
        // 拍照
        const takephoto = (key,index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,key,index)
                }, 
                function(err){
                    alert(err.toString())
                }, 
                {
                    quality: 50,
                    destinationType: Camera.DestinationType.DATA_URL,//返回FILE_URI类型
                    sourceType: Camera.PictureSourceType.CAMERA,//指定图片来自拍照
                    // cameraDirection: Camera.Direction.FRONT,//指定前后摄像头--好像没起作用
                    encodingType: Camera.EncodingType.PNG,
                    targetWidth: 150,
                    targetHeight: 150,
                    saveToPhotoAlbum:false
                });
        }
        // 从相册选择
        const checkphoto = (key,index) =>{
            navigator.camera.getPicture(
                function(data){
                    onSuccess(data,key,index)
                }, 
                function(err){
                    alert(err.toString())
                }, {
                quality: 50,
                destinationType: Camera.DestinationType.DATA_URL,//返回Base64编码字符串
                sourceType: Camera.PictureSourceType.SAVEDPHOTOALBUM,//指定图片来自相册
                encodingType: Camera.EncodingType.PNG,//指定返回图片是png格式还是jpeg
                targetWidth: 150,
                targetHeight: 150
            });
        }
        const showImg = (list) =>{
            
            const baseconfig = Http.config();
            var images = [];
            if(!list || list.length == 0){
                Notify({ type: 'warning', message: '暂无可查看图片' });
                return false;
            }else{
                list.forEach(elem =>{
                    images.push(baseconfig.baseUrl + elem.document_path);
                });
                ImagePreview({
                    images: images,
                    closeable:true,
                    startPosition: 1,
                    loop:true
                });
            }
        }

        onMounted(()=>{
            getCheckList()
        });
        return {
            list,
            sigleSubmit,
            allSubmit,
            cancelSubmit,
            writingCommit,
            takephoto,
            showImg,
            checkphoto,
            onSuccess,
            imageurl,
            typeId,
            seeForm,
            locationList,
            showLocation,
            onConfirm,
            rtList,
            listObj
        }
    },
}
</script>
<style scoped lang='less'>
.main_cont{
    height: calc(100% - 40px);
    overflow-y: auto;
    width: 100%;
}
.checkBox{
    width: 95%;
    background-color: #ffffff;
    border-radius: 5px;
    margin: 5px auto;
    .title{
        padding:10px;
        text-indent: 5px;
        font-size: 15px;
        font-family: 'blod';
        border-bottom: 1px solid #eeeeee;
    }
    .van-radio-group--horizontal{
        width: calc(100% - 20px);
        padding: 0 10px;
        justify-content: space-between;
        .van-radio{
            border-bottom: 0;
        }
    }
    .button{
        width:100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
}
.allSubmit{
    height: 35px;
    width: 100%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'blod';
    font-size: 16px;
}
</style>